<template>
  <div>
    <template v-for="(val) in chil">
      <!-- {{ val }} -->
      <el-submenu
        :index="'/product-detail/'+val.id"
        :key="val.id+'id'"
        v-if="val[props.children] && val[props.children].length > 0"
      >
        <template slot="title">{{ val[props.title] }}</template>
        <sub-item
          :chil="val[props.children]"
          :props="props"
          @getSubItem="onSubTtemClick"
        />
      </el-submenu>
      <el-menu-item
        :index="'/product-detail/'+val.id"
        v-else
        :key="val.id+'id'"
        @click="onSubTtemClick(val)"
        >{{ val[props.title] }}</el-menu-item
      >
    </template>
  </div>
</template>

<script>
export default {
  name: "subItem",
  props: {
    chil: {
      type: Array,
      default() {
        return [];
      },
    },
    props: {
      type: Object,
      default() {
        return {
          children: "children",
          label: "label",
          index: "id",
        };
      },
    },
  },
  methods: {
    onSubTtemClick(item) {
      this.$emit("getSubItem", item);
    },
  },
};
</script>
<style>
::v-deep .el-menu--horizontal {
  position: absolute;
  top:0px !important
}
::v-deep .is-active {
  border-bottom: none   !important;
  color: none !important; /* 重置选中项的颜色 */
  background-color: none !important; /* 重置选中项的背景色 */
}
::v-deep .is-active {
  color: none !important; /* 重置选中项的颜色 */
  background-color: none !important; /* 重置选中项的背景色 */
}
</style>