import request from '@/utils/request'

export function categoryThemeList(data) {
    return request({
        url: '/product/categoryThemeList',
        method: 'post',
        data
    })
}

export function categoryData(data) {
    return request({
        url: '/product/categoryData',
        method: 'post',
        data
    })
}
export function themeData(data) {
    return request({
        url: '/product/themeData',
        method: 'post',
        data
    })
}
export function productIndex() {
    return request({
        url: '/product/productIndex?type=test',
        method: 'get',
        
    })
}
export function featuredProducts(data) {
    return request({
        url: '/product/featuredProducts',
        method: 'post',
        data
    })
}
export function productsList(data) {
    return request({
        url: '/product/productsList',
        method: 'post',
        data
    })
}
export function productDetails(data) {
    return request({
        url: '/product/productInfo',
        method: 'post',
        data
    })
}
export function collectProduct(data) {
    return request({
        url: '/product/collectProduct',
        method: 'post',
        data
    })
}

export function getThemeName(data)
{
    return request({
        url: '/product/getThemeName',
        method: 'post',
        data
    })
}

export function getCategoryName(data)
{
    return request({
        url: '/product/getCategoryName',
        method: 'post',
        data
    })
}
