<template>
  <el-menu
    menu-trigger="click"
    mode="horizontal"
    active-text-color="#09f"
    unique-opened="true"
    @select="selectMenu"
  >
    <template v-for="val in menuList">
      <el-submenu
        :index="'/product-detail/' + val.id"
        v-if="val[props.children] && val[props.children].length > 0"
        :key="val.id + 'id'"
      >
        <template slot="title">
          <span>{{ val[props.title] }}</span>
        </template>
        <SubItem
          :chil="val[props.children]"
          :props="props"
          @getSubItem="onMenuItemClick"
        />
      </el-submenu>
      <el-menu-item
        :index="'/product-detail/' + val.id"
        v-else
        @click="onMenuItemClick(val)"
        :key="val.id + 'id'"
      >
        <span slot="title">{{ val[props.title] }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>
<script>
import SubItem from "./SubItem.vue";
export default {
  name: "subMenu",
  components: { SubItem },
  props: {
    menuList: {
      type: Array,
      default() {
        return [];
      },
    },
    props: {
      type: Object,
      default() {
        return {
          children: "children",
          title: "title",
          index: "id",
        };
      },
    },
  },
  methods: {
    onMenuItemClick(item) {
      this.$emit("getmenu", item);
    },
    selectMenu(index, indexPath) {
      // console.log(index,indexPath)
      // if (index == "/product-detail/100001") {
      //   console.log(index);
      //   this.$router.push({
      //     path: "product-list",
      //     query: { pid: 100001 },
      //   });
      // } else if (index == "/product-detail/100001") {
      //   this.$router.push({
      //     path: "product-list",
      //     query: { pid: 100002 },
      //   });
      // }
    },
  },
};
</script>
<style>
::v-deep .el-menu--horizontal {
  position: absolute;
  top: 0px !important;
}
</style>
